$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$fa-font-path: '~font-awesome/fonts';

@import '~ngx-toastr/toastr.css';
@import "fonts";
@import "~font-awesome/scss/font-awesome";
@import "variables";
@import "mixins";
@import "~bootstrap-sass/assets/stylesheets/bootstrap";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/theme";
@import "bootstrapCustomization";
@import "shared";
@import "spinner";
@import "sprite";
@import "bs-datepicker.4.0.0";

body {
  overflow: hidden;
}