@import "variables";

.toast-container {
  position: relative;
  margin: 0 45px 0 45px;
  font-size: 12px;

  .toast-info, .toast-error, .toast-warning, .toast-success {
    background-image: none;
    color: $black-gray;
    box-shadow: none;
    font-size: 12px;
    padding-bottom: 5px;
  }

	.toast-info {
		background-color: $black-gray;
  }
  
  .toast-title{
    font-size: 14px;
    color: $black-gray;

  }

  .toastr-message{
    font-size: 12px;
    color: $black-gray;
  }

	&.toast-top-center{
		width: auto;

		.ngx-toastr {
			padding: 15px 15px 15px 15px;
			text-align: left;
			min-width: 300px;
      width: 100%;
      
      &:not(.toast-close-button){
        &:hover{
          cursor: auto;
          box-shadow: none;
        }
      }

		}
	}

	.toast-error {
    background-color: #e2c199;
	}
  
  .toast-close-button{
    color: $black-gray;

    &:hover{
      cursor: pointer;
    }
  }
}
