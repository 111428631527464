%svg-common {
	background: url("../assets/images/sprite.svg") no-repeat;
}

.svg-AlertRed28 {
	@extend %svg-common;
	background-position: 58.08383233532934% 81.93548387096774%;
}

.svg-AlertRed28-dims {
	width: 28px;
	height: 25px;
}

.svg-accountPairingSettings40 {
	@extend %svg-common;
	background-position: 98.4472049689441% 40.39087947882736%;
}

.svg-accountPairingSettings40-dims {
	width: 40px;
	height: 28px;
}

.svg-avatar47 {
	@extend %svg-common;
	background-position: 0 88.19444444444444%;
}

.svg-avatar47-dims {
	width: 47px;
	height: 47px;
}

.svg-backButton32 {
	@extend %svg-common;
	background-position: 67.74193548387096% 54.78547854785479%;
}

.svg-backButton32-dims {
	width: 21px;
	height: 32px;
}

.svg-backButton46 {
	@extend %svg-common;
	background-position: 68.14159292035399% 43.47826086956522%;
}

.svg-backButton46-dims {
	width: 23px;
	height: 36px;
}

.svg-capture46 {
	@extend %svg-common;
	background-position: 14.873417721518987% 86.68941979522184%;
}

.svg-capture46-dims {
	width: 46px;
	height: 42px;
}

.svg-company28 {
	@extend %svg-common;
	background-position: 9.880239520958083% 98.0456026058632%;
}

.svg-company28-dims {
	width: 28px;
	height: 28px;
}

.svg-currentCases38 {
	@extend %svg-common;
	background-position: 54.32098765432099% 70.70707070707071%;
}

.svg-currentCases38-dims {
	width: 38px;
	height: 38px;
}

.svg-doctor28 {
	@extend %svg-common;
	background-position: 18.263473053892216% 98.0456026058632%;
}

.svg-doctor28-dims {
	width: 28px;
	height: 28px;
}

.svg-downloadIcon34 {
	@extend %svg-common;
	background-position: 28.353658536585368% 83.82838283828383%;
}

.svg-downloadIcon34-dims {
	width: 34px;
	height: 32px;
}

.svg-downloadScans22 {
	@extend %svg-common;
	background-position: 65.29411764705883% 80.89171974522293%;
}

.svg-downloadScans22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScansDark22 {
	@extend %svg-common;
	background-position: 71.76470588235294% 80.89171974522293%;
}

.svg-downloadScansDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-downloadScreenshots34 {
	@extend %svg-common;
	background-position: 38.71951219512195% 83.27868852459017%;
}

.svg-downloadScreenshots34-dims {
	width: 34px;
	height: 30px;
}

.svg-downloadSuccess24 {
	@extend %svg-common;
	background-position: 75.7396449704142% 58.80503144654088%;
}

.svg-downloadSuccess24-dims {
	width: 24px;
	height: 17px;
}

.svg-downloadsResources44 {
	@extend %svg-common;
	background-position: 99.68553459119497% 15.254237288135593%;
}

.svg-downloadsResources44-dims {
	width: 44px;
	height: 40px;
}

.svg-education52 {
	@extend %svg-common;
	background-position: 82.58064516129032% 32.758620689655174%;
}

.svg-education52-dims {
	width: 52px;
	height: 45px;
}

.svg-exportGallery34 {
	@extend %svg-common;
	background-position: 85.79881656804734% 68.40390879478828%;
}

.svg-exportGallery34-dims {
	width: 24px;
	height: 28px;
}

.svg-exportSettings34 {
	@extend %svg-common;
	background-position: 48.93617021276596% 82.46753246753246%;
}

.svg-exportSettings34-dims {
	width: 33px;
	height: 27px;
}

.svg-exportSettings50 {
	@extend %svg-common;
	background-position: 27.564102564102566% 30.508474576271187%;
}

.svg-exportSettings50-dims {
	width: 50px;
	height: 40px;
}

.svg-externalLinkAlt22 {
	@extend %svg-common;
	background-position: 41.76470588235294% 96.16613418530352%;
}

.svg-externalLinkAlt22-dims {
	width: 22px;
	height: 22px;
}

.svg-eyeIcon28 {
	@extend %svg-common;
	background-position: 26.646706586826348% 98.0456026058632%;
}

.svg-eyeIcon28-dims {
	width: 28px;
	height: 28px;
}

.svg-home46 {
	@extend %svg-common;
	background-position: 66.04938271604938% 70.70707070707071%;
}

.svg-home46-dims {
	width: 38px;
	height: 38px;
}

.svg-icn_pen50 {
	@extend %svg-common;
	background-position: 0 72.16494845360825%;
}

.svg-icn_pen50-dims {
	width: 48px;
	height: 44px;
}

.svg-iconWechat61 {
	@extend %svg-common;
	background-position: 58.47176079734219% 26.31578947368421%;
}

.svg-iconWechat61-dims {
	width: 61px;
	height: 50px;
}

.svg-invisalign40 {
	@extend %svg-common;
	background-position: 98.4472049689441% 47.20496894409938%;
}

.svg-invisalign40-dims {
	width: 40px;
	height: 13px;
}

.svg-iteroHeaderLogo61 {
	@extend %svg-common;
	background-position: 85.04983388704319% 0;
}

.svg-iteroHeaderLogo61-dims {
	width: 61px;
	height: 61px;
}

.svg-iteroLoginAsLogo86 {
	@extend %svg-common;
	background-position: 32.608695652173914% 0;
}

.svg-iteroLoginAsLogo86-dims {
	width: 86px;
	height: 86px;
}

.svg-iteroLoginLogo86 {
	@extend %svg-common;
	background-position: 0 30.508474576271187%;
}

.svg-iteroLoginLogo86-dims {
	width: 86px;
	height: 40px;
}

.svg-localization48 {
	@extend %svg-common;
	background-position: 15.286624203821656% 71.1864406779661%;
}

.svg-localization48-dims {
	width: 48px;
	height: 40px;
}

.svg-loginSettings40 {
	@extend %svg-common;
	background-position: 94.91017964071857% 55.932203389830505%;
}

.svg-loginSettings40-dims {
	width: 28px;
	height: 40px;
}

.svg-magnifingGlass26 {
	@extend %svg-common;
	background-position: 34.718100890207715% 97.41100323624596%;
}

.svg-magnifingGlass26-dims {
	width: 25px;
	height: 26px;
}

.svg-messages42 {
	@extend %svg-common;
	background-position: 99.0625% 28.716216216216218%;
}

.svg-messages42-dims {
	width: 42px;
	height: 39px;
}

.svg-messages80 {
	@extend %svg-common;
	background-position: 62.4113475177305% 0;
}

.svg-messages80-dims {
	width: 80px;
	height: 75px;
}

.svg-messagesAlert22 {
	@extend %svg-common;
	background-position: 78.23529411764706% 80.63492063492063%;
}

.svg-messagesAlert22-dims {
	width: 22px;
	height: 20px;
}

.svg-more-icon22 {
	@extend %svg-common;
	background-position: 97.1830985915493% 52.21518987341772%;
}

.svg-more-icon22-dims {
	width: 7px;
	height: 19px;
}

.svg-orders80 {
	@extend %svg-common;
	background-position: 0 50.98039215686274%;
}

.svg-orders80-dims {
	width: 74px;
	height: 80px;
}

.svg-patientEdit40 {
	@extend %svg-common;
	background-position: 97.53846153846153% 69.49152542372882%;
}

.svg-patientEdit40-dims {
	width: 37px;
	height: 40px;
}

.svg-patients80 {
	@extend %svg-common;
	background-position: 25.964912280701753% 50.98039215686274%;
}

.svg-patients80-dims {
	width: 77px;
	height: 80px;
}

.svg-penIcon20 {
	@extend %svg-common;
	background-position: 84.21052631578948% 80.63492063492063%;
}

.svg-penIcon20-dims {
	width: 20px;
	height: 20px;
}

.svg-pendingCases40 {
	@extend %svg-common;
	background-position: 98.4472049689441% 83.05084745762711%;
}

.svg-pendingCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-plus-icon22 {
	@extend %svg-common;
	background-position: 98.8538681948424% 57.142857142857146%;
}

.svg-plus-icon22-dims {
	width: 13px;
	height: 13px;
}

.svg-practiceSettings45 {
	@extend %svg-common;
	background-position: 0 100%;
}

.svg-practiceSettings45-dims {
	width: 33px;
	height: 34px;
}

.svg-printRx22 {
	@extend %svg-common;
	background-position: 48.23529411764706% 95.85987261146497%;
}

.svg-printRx22-dims {
	width: 22px;
	height: 21px;
}

.svg-printRxDark22 {
	@extend %svg-common;
	background-position: 54.705882352941174% 95.85987261146497%;
}

.svg-printRxDark22-dims {
	width: 22px;
	height: 21px;
}

.svg-printSuccess24 {
	@extend %svg-common;
	background-position: 93.78698224852072% 89.34169278996865%;
}

.svg-printSuccess24-dims {
	width: 24px;
	height: 16px;
}

.svg-privacy100 {
	@extend %svg-common;
	background-position: 0 0;
}

.svg-privacy100-dims {
	width: 90px;
	height: 90px;
}

.svg-rx38 {
	@extend %svg-common;
	background-position: 77.77777777777777% 69.76744186046511%;
}

.svg-rx38-dims {
	width: 38px;
	height: 34px;
}

.svg-rx52 {
	@extend %svg-common;
	background-position: 82.58064516129032% 48.611111111111114%;
}

.svg-rx52-dims {
	width: 52px;
	height: 47px;
}

.svg-rx80 {
	@extend %svg-common;
	background-position: 53.54609929078014% 49.42965779467681%;
}

.svg-rx80-dims {
	width: 80px;
	height: 72px;
}

.svg-rxSettings40 {
	@extend %svg-common;
	background-position: 42.2360248447205% 30.508474576271187%;
}

.svg-rxSettings40-dims {
	width: 40px;
	height: 40px;
}

.svg-settings45 {
	@extend %svg-common;
	background-position: 100% 0;
}

.svg-settings45-dims {
	width: 45px;
	height: 45px;
}

.svg-systemInformation40 {
	@extend %svg-common;
	background-position: 29.81366459627329% 71.1864406779661%;
}

.svg-systemInformation40-dims {
	width: 40px;
	height: 40px;
}

.svg-totalCases40 {
	@extend %svg-common;
	background-position: 42.2360248447205% 71.1864406779661%;
}

.svg-totalCases40-dims {
	width: 40px;
	height: 40px;
}

.svg-trashIcon21 {
	@extend %svg-common;
	background-position: 68.8953488372093% 23.88535031847134%;
}

.svg-trashIcon21-dims {
	width: 18px;
	height: 21px;
}

.svg-viewer61 {
	@extend %svg-common;
	background-position: 85.04983388704319% 20.26578073089701%;
}

.svg-viewer61-dims {
	width: 61px;
	height: 34px;
}

